import * as React from 'react';
import "./BodyCopyDivider.css?__remix_sideEffect__";

const BodyCopyDivider = ({ heading, copy }) => {
  if (!heading) {
    console.error('BodyCopyDivider component requires a heading prop');
    return null;
  }

  const copyElement = copy ? <p className='copy'>{copy}</p> : null;

  return (
    <div className={`BodyCopyDivider ${!copy ? 'nopadding' : ''}`}>
      <h2 className='widget-label'>{heading}</h2>
      {copyElement}
    </div>);

};
export default BodyCopyDivider;